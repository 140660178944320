import modalEvents from '../constants/modal-events';

const closeOnEscape = (event) => {
  if (event.key === 'Escape') {
    closeLoginModal();
  }
};

const initLoginModal = () => {
  const btnLogin = document.querySelectorAll('.js-login-button');
  const loginModalClose = document.querySelectorAll('.js-login-modal-close');

  btnLogin.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      openLoginModal();
    });
  });

  loginModalClose.forEach((buttonClose) => {
    buttonClose.addEventListener('click', closeLoginModal);
  });
};

const openLoginModal = () => {
  if (!('customElements' in window)) {
    return (window.location.href = '/account/login');
  }

  const loginModal = document
    .querySelector('hb-modal#login-modal')
    .shadowRoot.querySelector('#login-modal');
  if (!loginModal) return;

  window.addEventListener('keydown', closeOnEscape);

  const openModalEvent = new CustomEvent(modalEvents.open);
  loginModal.dispatchEvent(openModalEvent);
};

const closeLoginModal = () => {
  const loginModal = document
    .querySelector('hb-modal#login-modal')
    .shadowRoot.querySelector('#login-modal');
  if (!loginModal) return;

  window.removeEventListener('keydown', closeOnEscape);

  const openModalEvent = new CustomEvent(modalEvents.close);
  loginModal.dispatchEvent(openModalEvent);
};

export { closeLoginModal, initLoginModal, openLoginModal };
